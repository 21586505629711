'use client';

import { FormEventHandler, useState } from 'react';
import { toast } from 'react-toastify';
import { useRouter } from 'next/navigation';
import { z } from 'zod';
import { Alert, Box, Link as MuiLink, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@qb/frontend/components/LoadingButton';
import { postRequestPromise } from '@qb/httpRequest/RequestPromise';
import { WebAPIPrefix, SERVER_ERROR_MESSAGE } from '@qb/httpRequest/constants';
import { isApiBusinessLogicError } from '@qb/httpRequest/utils';
import { LinkOnClickOrRedirect } from '@/src/components/Common/LinkOnClickOrRedirect';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { SignupView } from '../SignupView/SignupView';
type ForgotPasswordRequest = {
  email: string;
};
type ForgotPasswordResponse = {
  redirectURI: string;
};
export function postForgotPasswordRequest(payload: ForgotPasswordRequest): Promise<void | ForgotPasswordResponse> {
  return postRequestPromise(`${WebAPIPrefix}/forgotpassword`, payload);
}
const schema = z.object({
  email: z.string({
    required_error: 'Email is required.'
  }).email('Email must be valid.')
});
type ForgotPasswordFormProps = {
  shouldRedirect?: boolean;
};
export const ForgotPasswordForm = ({
  shouldRedirect = false
}: ForgotPasswordFormProps) => {
  const {
    setActiveDrawer,
    hideDrawer
  } = useRightDrawerContext();
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const handleSubmit: FormEventHandler = async event => {
    event.preventDefault();
    setErrorMessage('');
    const data = schema.safeParse({
      email
    });
    if (!data.success) {
      const formatted = data.error.errors.map(err => err.message).join(', ');
      setErrorMessage(formatted);
      return;
    }
    setIsSending(true);
    try {
      const data = await postForgotPasswordRequest({
        email
      });
      if (data) {
        hideDrawer();
        router.replace('/forgotPasswordLinkSent');
      } else {
        toast.error(SERVER_ERROR_MESSAGE);
      }
    } catch (e) {
      if (isApiBusinessLogicError(e)) {
        setErrorMessage(e.message);
      }
    } finally {
      setIsSending(false);
    }
  };
  return <Box component="form" onSubmit={handleSubmit} data-sentry-element="Box" data-sentry-component="ForgotPasswordForm" data-sentry-source-file="ForgotPasswordForm.tsx">
      {errorMessage && <Alert>{errorMessage}</Alert>}
      <Box sx={{
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: 2
    }} data-sentry-element="Box" data-sentry-source-file="ForgotPasswordForm.tsx">
        <TextField required fullWidth type="text" name="email" label="Email address" value={email} onChange={event => setEmail(event.target.value)} autoFocus={true} data-sentry-element="TextField" data-sentry-source-file="ForgotPasswordForm.tsx" />
        <LoadingButton type="submit" loading={isSending} size="large" fullWidth variant="contained" data-sentry-element="LoadingButton" data-sentry-source-file="ForgotPasswordForm.tsx">
          Continue
        </LoadingButton>
      </Box>
      <Box justifySelf="start" mt={2} display="flex" data-sentry-element="Box" data-sentry-source-file="ForgotPasswordForm.tsx">
        <Typography variant="bodyMedium" mr={0.5} data-sentry-element="Typography" data-sentry-source-file="ForgotPasswordForm.tsx">{`Don't have an account yet?`}</Typography>
        <MuiLink href="/signup" onClick={() => setActiveDrawer(<SignupView viewType="drawer" />)} component={LinkOnClickOrRedirect} shouldRedirect={shouldRedirect} variant="bodyMedium" underline="always" data-sentry-element="MuiLink" data-sentry-source-file="ForgotPasswordForm.tsx">
          Sign Up
        </MuiLink>
      </Box>
    </Box>;
};